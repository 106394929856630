// External Dependencies
import React, { Component } from 'react';
import isEmail from 'validator/lib/isEmail';
// import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  PrivacyPolicy,
  TermsOfService,
} from './shared/Links';

// Component Definition
class PromotionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      districtOrSchool: '',
      districtOrSchoolTouched: false,
      email: '',
      emailTouched: false,
      howTheyHeard: '',
      finePrintChecked: false,
      location: '',
      locationTouched: false,
      name: '',
      nameTouched: false,
      numberOfOrganizations: '',
      privacyPolicyChecked: false,
    };
  }

  handleBlur = (e) => {
    this.setState({ [`${e.target.name}Touched`]: true });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  render() {
    const {
      districtOrSchool,
      districtOrSchoolTouched,
      email,
      emailTouched,
      howTheyHeard,
      finePrintChecked,
      location,
      locationTouched,
      name,
      nameTouched,
      numberOfOrganizations,
      privacyPolicyChecked,
    } = this.state;

    const districtOrSchoolValid = districtOrSchool.trim().length > 0;
    const emailPresent = email.trim().length > 0;
    const emailValid = isEmail(email);
    const locationValid = location.trim().length > 0;
    const nameValid = name.trim().length > 0;

    let emailErrorMessage = null;
    if (!emailPresent) {
      emailErrorMessage = 'Required';
    } else if (!emailValid) {
      emailErrorMessage = 'Please enter a valid email';
    }

    const districtOrSchoolErrorMessage = !districtOrSchoolValid && 'Required';
    const locationErrorMessage = !locationValid && 'Required';
    const nameErrorMessage = !nameValid && 'Required';

    const districtOrSchoolError = districtOrSchoolTouched && !!districtOrSchoolErrorMessage;
    const emailError = emailTouched && !!emailErrorMessage;
    const locationError = locationTouched && !!locationErrorMessage;
    const nameError = nameTouched && !!nameErrorMessage;

    const formValid = districtOrSchoolValid
      && emailValid
      && locationValid
      && nameValid
      && finePrintChecked
      && privacyPolicyChecked;

    return (
      <div>
        <form
          action="/promotion_success"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          method="post"
          name="promotion_success"
        >
          <input type="hidden" name="form-name" value="promotion_success" />

          <p hidden>
            <label htmlFor="hidden">
              Don&apos;t fill this out:{' '}
              <input
                name="bot-field"
                onChange={(e) => this.handleChange(e)}
              />
            </label>
          </p>

          <h3>
            Director or Administrator Information
          </h3>

          <label htmlFor="name">
            Full Name
            <input
              className={nameError ? 'input-error' : ''}
              id="name"
              name="name"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={name}
            />
            {nameError && (
              <p className="error">{nameErrorMessage}</p>
            )}
          </label>

          <label htmlFor="email">
            Email
            <input
              className={emailError ? 'input-error' : ''}
              id="email"
              name="email"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={email}
            />
            {emailError && (
              <p className="error">{emailErrorMessage}</p>
            )}
          </label>

          <h3>
            District and/or School Information
          </h3>

          <label htmlFor="districtOrSchool">
            Name of District and/or School
            <input
              className={districtOrSchoolError ? 'input-error' : ''}
              id="districtOrSchool"
              name="districtOrSchool"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={districtOrSchool}
            />
            {districtOrSchoolError && (
              <p className="error">{districtOrSchoolErrorMessage}</p>
            )}
          </label>

          <label htmlFor="location">
            Location — City, State
            <input
              className={locationError ? 'input-error' : ''}
              id="location"
              name="location"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={location}
            />
            {locationError && (
              <p className="error">{locationErrorMessage}</p>
            )}
          </label>

          <h3>
            &quot;Switch to Presto&quot; Information
          </h3>

          <label htmlFor="numberOfOrganizations">
            Number of Organizations Switching to Presto
            <select
              id="numberOfOrganizations"
              name="numberOfOrganizations"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="number"
              value={numberOfOrganizations}
            >
              <option value="1-5">1-5</option>
              <option value="6-10">6-10</option>
              <option value="11-20">11-20</option>
              <option value="20+">20+</option>
            </select>
          </label>

          <p className="caption">
            For example, a HS band, HS choir, and MS band would be 3 organizations.
          </p>

          <p className="caption">
            Presto Assistant offers support for these organization types:<br />
            Band, Choir, Dance, Guitar, Mariachi, Orchestra, and Theater
          </p>

          <label htmlFor="howTheyHeard">
            How did you hear about Presto Assistant?
            <input
              id="howTheyHeard"
              name="howTheyHeard"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={howTheyHeard}
            />
          </label>

          <input
            checked={finePrintChecked}
            id="fine-print"
            name="finePrintChecked"
            onChange={this.handleCheck}
            type="checkbox"
            value={finePrintChecked}
          />

          <label htmlFor="fine-print">
            I have read and understand the &quot;fine print*&quot; listed below.
          </label>

          <input
            checked={privacyPolicyChecked}
            id="privacy-policy"
            name="privacyPolicyChecked"
            onChange={this.handleCheck}
            type="checkbox"
            value={privacyPolicyChecked}
          />

          <label htmlFor="privacy-policy">
            I have read and acknowledge Presto&apos;s{' '}
            <TermsOfService openInNewTab /> and <PrivacyPolicy openInNewTab />.
          </label>

          <div className="button-wrapper">
            <button
              className="button special-2"
              disabled={!formValid}
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default PromotionForm;
