// External Dependencies
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import React from 'react';

// Internal Dependencies
import CurveSection from '../components/shared/CurveSection';
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import PromotionForm from '../components/PromotionForm';
import PromotionFinePrint from '../components/PromotionFinePrint';

// Component definition
const Promotion = () => {
  return (
    <Layout>
      <Meta title="Switch Promotion" />

      <HeaderGeneric
        subtitle="Fill out the form below, and we will contact you soon."
        title={'Enter the "Presto Change-O" Promotion'}
      />

      <div id="main" className="form">
        <Card
          className="switch-card"
          data-variant="outlined"
        >
          <CardContent>
            <PromotionForm />
          </CardContent>
        </Card>
      </div>

      <CurveSection>
        <Container maxWidth="md">
          <Card
            className="switch-card"
            data-variant="outlined"
            variant="outlined"
          >
            <CardContent className="align-left">
              <PromotionFinePrint />
            </CardContent>
          </Card>
        </Container>
      </CurveSection>
    </Layout>
  );
};

export default Promotion;
